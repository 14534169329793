import { TranslationResource } from '../models';

export const TRANSLATIONS_VERSION = '1.1.3';

export const TRANSLATIONS: TranslationResource[] = [
  { prefix: `{{lang}}/auth.`, suffix: '.json' },
  { prefix: `{{lang}}/core.`, suffix: '.json' },
  { prefix: `{{lang}}/portal.`, suffix: '.json' },
  { prefix: `{{lang}}/shared.`, suffix: '.json' },
  { prefix: `{{lang}}/widget.`, suffix: '.json' },
];
