import * as Sentry from '@sentry/angular';
import { environment } from 'src/environments/environment';
import { HelperMethods } from '../../modules/widget/utils/helper-methods';

export class SentryService {
  static init(): void {
    if (environment.production) {
      Sentry.init({
        dsn: environment.sentryDsn, integrations: [Sentry.browserTracingIntegration()],
        tracesSampleRate: 1.0, tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
        replaysSessionSampleRate: 0.0, replaysOnErrorSampleRate: 0.0, environment: 'production', beforeSend(event, _) {
          return HelperMethods.getLogRocketSessionURL().then(sessionURL => {
            if (sessionURL) {
              if (!event.extra) {
                event.extra = {};
              }
              event.extra.sessionURL = sessionURL;
            }
            return event;
          });
        }
      });
    }
  }

  static handleException(error: any): void {
    Sentry.captureException(error);
  }
}
